import React from 'react'
import synology from '../images/technologies/tech-synology-8403c192e3fbfd5fc9157fa6005f126d.png'
import cisco from '../images/technologies/tech-cisco-6afab7c941a2121f27e5a69ab46601a1.png'
import fortinet from '../images/technologies/fortinet.png'

import eset from '../images/technologies/eset.png'

import checkpoint from '../images/technologies/checkpoint.png'
import paloalto from '../images/technologies/paloatlo.png'
import bitdefender from '../images/technologies/bitdefender.png'

import hp from '../images/technologies/hp.png'
import dell from '../images/technologies/dell.png'
import lenovo from '../images/technologies/lenovo.png'

const clients = [
    {
        name: 'synology',
        logo: synology,
        url: 'https://www.synology.com/en-uk'
    },
    {
        name: 'cisco',
        logo: cisco,
        url: 'https://www.cisco.com/'
    },
    {
        name: 'fortinet',
        logo: fortinet,
        url: 'https://www.fortinet.com/'
    },
   
    {
        name: 'eset',
        logo: eset,
        url: 'https://www.eset.com/lk/'
    },
   
    {
        name: 'bitdefender',
        logo: bitdefender,
        url: 'https://www.bitdefender.com/'
    },
   
    {
        name: 'checkpoint',
        logo: checkpoint,
        url: 'https://www.checkpoint.com/'
    },
 
    {
        name: 'paloalto',
        logo: paloalto,
        url: 'https://www.paloaltonetworks.com/'
    },
 
    {
        name: 'lenovo',
        logo: lenovo,
        url: 'https://www.lenovo.com/'
    },
    {
        name: 'dell',
        logo: dell,
        url: 'https://www.dell.com/'
    },
    {
        name: 'hp',
        logo: hp,
        url: 'https://www.hp.com/'
    }

]

const Hometech = () => {
    return (
        <section  data-scrollax-parent="true" id="partnership">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Partnership<span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Partnership</h3>
                    <h2>Technology  <span>Partnership</span></h2>
                 
                </div>
            </div>
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className='respimg' alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default Hometech
