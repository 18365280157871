import React from 'react'
import NumbersBgImage from '../images/bg/bg-stats.jpg'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import cloudsecurity from '../images/services/new/pragicts-cloudops-security.jpeg'
import infrastructure from '../images/services/new/pragicts-cloudops-infrastructure.jpg'
import iot from '../images/services/new/pragicts-cloudops-iot.jpg'
import saas from '../images/services/new/pragicts-cloudops-sase.jpg'
import security from '../images/services/new/security.jpg'
import BgImage from '../images/bg/services-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

const HomeStats = () => {

    return (
        <section className="dark-bg no-padding" id="services">
             <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Services </div>
            <div >
                <div className="section-title fl-wrap">
                    <h3>Our Services</h3>
                    <h2>CloudOps <span> Services</span></h2>
          
                </div>
        <div className="fs-carousel-wrap fl-wrap full-height slider-carousel-wrap">
            <div className="fs-carousel fl-wrap full-height cur_carousel-slider-container">
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><Link to="/services">Cloud Security </Link></h3>
                        <Link to="/services" className="fs-carousel-link">More Details</Link>
                    </div>
                    <div className="carousle-item-number"><span>01.</span></div>
                    <div className="bg"  data-bg={cloudsecurity}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Cloud Infrastructure Management </a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>02.</span></div>
                    <div className="bg"  data-bg={infrastructure}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Internet of Things (IoT)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>03.</span></div>
                    <div className="bg"  data-bg={iot}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '75px', paddingBottom: '75px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">SASE (Secure Assess Service Edge) and SSE (Secure Service Edge)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>04.</span></div>
                    <div className="bg"  data-bg={saas}></div>
                    <div className="overlay"></div>
                </div>
              
    
               
            </div>
            <div className="sp-cont sarr-contr sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
            <div className="sp-cont sarr-contr sp-cont-next"><i className="fal fa-arrow-right"></i></div>
            <div className="slider-nav-counter"></div>
        </div>
        </div>
    </section>

    )
}

export default HomeStats
