import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeSolutions = () => {
    return (
        <section data-scrollax-parent="true" id="KPIs">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>highlights </div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>KPIs</h3>
                    <h2>CLOUDOPS <span> HIGHLIGHTS</span></h2>
          
                </div>
                <div className="process-wrap fl-wrap">
                    <ul>
                        <li>
                            <div className="time-line-icon">
                                <i className="fab pragicts-analysis-icon"></i>
                            </div>
                            <h4>Established</h4>
                            <div className="process-details">
                                {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                                <p>Impeccable Track Record .</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">01.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-requirements-articulation-icon"></i>
                            </div>
                            <h4>Comprehensive Coverage</h4>
                            <div className="process-details">
                                {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                                <p>All Under One Roof (IaaS | SaaS | PaaS | Bespoke)</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">02.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-design-icon"></i>
                            </div>
                            <h4>Technology Agnostic</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>Solution Focused</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">03.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-support-icon"></i>
                            </div>
                            <h4>Bespoke</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>Tailored to Exact Requirements</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">04.</span>
                        </li>
                      
                    </ul>
                </div>
            </div>
           
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeSolutions
